// helpers
@import './helpers/globals';

// components
@import './components/stripe';
@import './components/button';
@import './components/form';
@import './components/header';
@import './components/footer';
@import './components/text';
@import './components/bookCard';
@import './components/bookCarousel';
@import './components/antd';
@import './components/completedOrder';

// pages
@import './pages/landing';
@import './pages/details';
@import './pages/cart';
@import './pages/checkout';

html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  height: 100%;
  width: 100%;
  background: $background-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
span,
p,
label,
input,
select,
section,
footer,
header,
nav,
ul,
li,
button,
tr,
td,
th {
  font-family: 'Roboto', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  overflow-wrap: break-word;
}

label {
  font-weight: 500;
}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

#root {
  min-height: 100vh;
}

::selection {
  background: $highlight-color;
  color: $white;
}

code {
  user-select: text;
  background: rgba(0, 0, 0, 0.05);
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 0.8em;
  line-height: 1.5;
}

label {
  font-weight: 500;
}

.hidden {
  display: none !important;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

button {
  outline: none !important;
}

.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $background-color;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
}

#root {
  overflow-x: scroll;
}

.spinner {
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	left: 50%;
}
