.footer-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $header-background;
  z-index: 5;
  padding: 0 10px;
  margin-top: 20px;

  .footer-content {
    height: 80px;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
  }

  .logo {
    width: 130px;
  }

  .copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    row-gap: 5px;
    color: $white;

    a {
      color: $white;

      &:hover {
        color: $white;
      }
    }

    span {
      text-align: right;
    }
  }
}
