.details-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  align-self: center;

  .card {
    margin: 5px;
    background: #fff;
    box-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 10%);
  }

  .main-details-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 450px;
    margin-top: 10px;
    background: unset;

    .book-details-wrapper {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 20px;
      background: #fff;
      margin-right: 10px;
      box-shadow: 0.2rem 0rem 0.1rem rgb(0 0 0 / 10%);

      .book-details-image-wrapper {
        width: 356px;
        padding: 10px;
      }
  
      .book-details-description-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        padding-left: 15px;
        padding-top: 5px;

        .author {
          margin-top: 5px;
        }

        .description {
          text-overflow: ellipsis;
          overflow: hidden;
          height: 300px;
          width: 100%;
          white-space: pre-wrap;
        }
      }
    }

    .price-details-wrapper {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 10px;
      padding: 20px 10px;
      background: #fff;

      .price {
        color: $highlight-color;
        margin-bottom: 20px;
      }

      .primary.long {
        width: 100%;
        height: 45px;
        font-weight: 700;
      }

      .marketing-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        row-gap: 10px;
        padding: 30px 0;
        border-top: 1px solid $background-color;
        border-bottom: 1px solid $background-color;

        .marketing {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          column-gap: 5px;

          &.bold {
            p {
              font-weight: bold;
            }
          }

          .icon {
            width: 30px;
          }
        }
      }
    }
  }

  .additional-details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 10px;
    padding: 10px;
    width: 100%;

    h3 {
      font-size: 18px;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid $background-color;
    }

    .product-details-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      row-gap: 10px;
      flex-wrap: wrap;
      max-height: 100px;

      span {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        column-gap: 5px;
        margin-right: 150px;

        .bold {
          font-weight: bold;
        }
      }
    }
  }

  .suggestions-wrapper {
    h3 {
      font-size: 18px;
      width: 100%;
      padding: 10px 0;
      margin: 0 10px;
      border-bottom: 1px solid $background-color;
    }
  }
}