.landing-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  align-self: center;
  background-color: $white;

  .not-found {
    margin: 100px auto;
  }

  .banner-carousel-wrapper {
    max-width: 910px;
    width: 100%;
    height: 290px;
    margin-bottom: 20px;

    .carousel-item {
      height: 293px;
      text-align: center;
      background-color: $white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      column-gap: 30px;
    }

    .book-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      row-gap: 10px;
    }
  }
}