.cart-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  align-self: center;
  justify-content: flex-start;
  min-height: calc(100vh - 266px);

  h1 {
    align-self: flex-start;
    margin-top: 25px;
    font-size: 24px;
  }

  .card {
    margin: 5px;
    background: #fff;
    box-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 10%);
  }

  .cart-top-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 20px;

    .cart-top-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 75px;
      border-bottom: 1px solid $background-color;

      .cart-total {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 75px;

        .icon {
          width: 20px;
          margin-right: 10px;
        }
      }

      .action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 22px;

        a {
          &:hover {
            text-decoration: unset;
          }
        }
        .primary {
          width: 265px;
          height: 42px;
          font-weight: 700;
        }

        & > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }

    .cart-bottom-content {
      padding-top: 10px;
    }
  }

  .cart-total-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 20px 20px 0;

    .upper-section {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      width: 100%;
      height: 95px;
      border-bottom: 1px solid $background-color;
      column-gap: 280px;
      padding: 10px 0;

      & > div {
        row-gap: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
      }

      p {
        font-size: 16px;

        &.highlight {
          color: $highlight-color;
          font-weight: 700;
        }
      }

    }

    .lower-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 160px;

      .cta-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 265px;
        row-gap: 10px;

        a {
          &:hover {
            text-decoration: unset;
          }
        }

        .primary {
          width: 265px;
          height: 42px;
          font-weight: 700;
        }
      }
    }
  }

  .continue-shopping {
    a {
      &:hover {
        text-decoration: unset;
      }
    }

    .continue {
      margin: 15px 0 25px;
      width: 150px;
      height: 45px;
      background: #cacaca;
      color: $base-color;
  
      &:hover {
        text-decoration: unset;
        background: #d7d7d7;
      }
    }
  }

  .cart-main-details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;

    h3 {
      font-size: 18px;
      width: 100%;
      padding-bottom: 10px;
      margin: 0;
      border-bottom: 1px solid $background-color;
    }

    .book-details-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding: 20px 10px 20px 0;
      border-bottom: 1px solid $background-color;

      &:last-child {
        border-bottom: unset;
        padding-bottom: 10px;
      }

      .book-details-description-wrapper {
        width: 100%;
        padding-left: 20px;

        .title {
          font-size: 18px;
        }
        .price {
          font-size: 18px;
          color: $highlight-color;
        }
      }

      .price-qty-details-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        align-self: center;
        width: 140px;
        height: 155px;

        .qty {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 140px;
        }

        .price {
          color: $highlight-color;
          font-size: 20px;
        }

        .remove {
          height: 35px;
          width: 100px;
          color: $base-color2;
          background: none;
          padding: 0;
          line-height: 25px;
          border: 1px solid $base-color3;

          &:hover {
            background: $background-color;
          }
        }
      }
    }
  }

  .suggestions-wrapper {
    h3 {
      font-size: 18px;
      width: 100%;
      padding: 10px 0;
      margin: 0 10px;
      border-bottom: 1px solid $background-color;
    }
  }

  .cart-empty-content-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 20px 10px;
  }

  .cart-empty-content-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 20px 10px;

    h3 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    a {
      &:hover {
        text-decoration: unset;
      }
      .primary {
        margin: 10px 0 30px;
        height: 45px;
        width: 150px;
        font-weight: 700;
      }
    }
  }
}