.order-wrapper {
  width: 100%;
  height: 500px;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .order-cta {
    display: flex;
    justify-content: center;
  }
  
  .order-title {
    color: $base-color;
    font-weight: 500;
    margin-bottom: 12px;
    font-size: 25px;
    text-align: center;
    line-height: 40px;
    align-self: center;
  }
  
  .order-message {
    color: $base-color;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
  }

  a {
    &:hover {
      text-decoration: unset;
    }
    .primary {
      font-weight: 700;
      height: 45px;
      width: 200px;
    }
  }
}