h1 {
  font-size: 28px;
}

h2 {
  font-size: 24px;
}

p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: $link-color;

  &:hover:not(.primary) {
    color: $link-color;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  button {
    &:hover {
      text-decoration: none;
    }
  }
}
