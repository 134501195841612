$base-color: #333;

$base-color2: #999;
$base-color3: #ccc;

$highlight-color: #ff0072;
$background-color: #f0f0f0;
$header-background: #4c394e;
$header-background-dark: #403042;

$link-color: #333;
$btn-background-color: #10bbd5;
$btn-background-color-hover: #29d5ef;

$white: #ffffff;
