.checkout-page-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: center;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin-top: 20px;

  .card {
    margin: 5px;
    padding: 20px;
    background: #fff;
    box-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 10%);

    h3 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }

  .checkout-main-column-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 840px;
    width: 100%;

    .steps-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 15px;

      .step {
        width: 50%;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;

        &:last-child {
          border-bottom-right-radius: 6px;
          border-top-right-radius: 6px;
          border-bottom-left-radius: unset;
          border-top-left-radius: unset;
        }

        &.active {
          color: $white;
          background-color: $btn-background-color;
        }
      }

      .divider {
        position: absolute;
        top: 6px;
        left: calc(50% - 14px);
        width: 28px;
        height: 28px;
        background: $btn-background-color;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }

    .address-details-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      .address-form {
        width: 100%;
      }
    }

    .payment-details-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      .stripe-form-wrapper {
        width: 50%;
      }

      .primary {
        height: 45px;
        font-weight: 700;
      }

      .consent {
        margin-top: 15px;
      }
    }
  }

  .checkout-summary-column-wrapper {
    max-width: 350px;
    width: 100%;
    height: auto;
    background: #fff;
    box-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 10%);
    // position: fixed;
    // top: 0;
    // right: 0;
    
    .summary-details-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      .summary-header {
        height: 70px;
        width: 100%;
        background-color: $highlight-color;
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        h3 {
          color: $white;
          font-size: 18px;
          font-weight: 700;
        }

        .mini-total-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          span {
            font-weight: 700;
            color: $white;
          }

          .cart-total {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            column-gap: 10px;
          }

          .cart-icon {
            width: 20px;
          }

          .total {
            justify-self: flex-end;
          }
        }
      }
      .summary-body {
        padding: 5px 15px 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .summary-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid $background-color;
          padding: 10px 0;

          &:last-child {
            border-bottom: none;
          }

          .title {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }

          .bold {
            font-weight: 700;
          }

          .highlight {
            color: $highlight-color;
            font-size: 18px;
          }
        }
      }
    }
  }

  .stripe-form, .address-form { 
    .ant-form-item {
      margin: 0 0 5px;

      .ant-form-item-label {
        padding: 0;

        label {
          height: 35px;
          font-weight: normal;
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          column-gap: 3px;

          &:before {
            font-size: 18px;
          }
        }
      }

      .ant-input-lg {
        border-radius: 6px;
        height: 34px;
        padding: 6px 12px;
        outline: none;
        border: 1px solid $base-color3;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s,-webkit-box-shadow ease-in-out 0.15s;

        &:focus {
          box-shadow: none;
          border-color: $base-color;
        }
      }
    }
  }
}